<template>
  <!-- <div class="pack-box" :class="`pack-box${copyright.entrantsType}`">123</div> -->
  <div class="examPackPartner">
    <div class="partenr_top">
      <div class="top_center">
        <!-- 面包屑 -->
        <div class="crumbs">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>试题题库</el-breadcrumb-item>
            <el-breadcrumb-item>{{ detail.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="name">{{ detail.name }}</div>
        <div class="tip">
          本试题知识点分布合理，涵盖了记忆、理解、应用、分析、评价、创造六个维度
        </div>
      </div>
      <div class="top_tip">
        <div class="tip_left">
          <div class="tip_name twoEllipsis">
            <span class="name">题库描述</span>
            <span class="tip">{{ detail.introduction }}</span>
          </div>
          <div class="tip_tip">
            <span class="one">时长</span>
            <span class="two">{{ detail.examTime }}分钟</span>
            <span class="three">·</span>
            <span class="one">学习人数</span>
            <span class="two">{{ detail.clicks }}</span>
            <span class="three">·</span>
            <span class="one">试卷数量</span>
            <span class="two">{{ detail.SubjectNum }}套</span>
          </div>
        </div>
        <div class="tip_right">
          <div class="right_price">
            ￥<span class="price" v-if="detail.entrantsPrice || detail.entrantsPrice == 0">{{
              detail.entrantsPrice.toFixed(2) }}</span>
          </div>
          <div class="right_btn" @click="goOrder()">{{ detail.status == 1 ? "已购买" : "立即购买" }}</div>
        </div>
      </div>
    </div>
    <div class="partenr_bottom">
      <div class="back">
        试卷列表
        <div class="line"></div>
      </div>
      <div class="dis_flex_between">
        <div v-if="list && list.length > 0" class="list">
          <div v-for="(item, index) in list" :key="index" class="item" @click="tabId = item.id">
            <img class="item-image" src="@/assets/img/partner/papers1.png" />
            <div class="item-content">
              <div class="item-title ellipsis">{{ item.name }}</div>
              <div class="item-count">共{{ item.subjectNum }}道题</div>
              <!-- <div class="item-tips"> -->
              <!-- {{ detail.status == 1 ? "已购买" : "￥" + item.paperPrice }} -->
              <!-- {{ detail.status == 1 ? "已购买" : "" }} -->
              <!-- </div> -->
            </div>

            <el-button v-if="detail.status == 1 ||
              !item.packagePrice ||
              item.packagePrice == 0
              " :loading="item.loading" class="btn" @click="goLearn(item)">
              开始答题
            </el-button>
            <div v-else class="keep-learn keep-ok">开始答题</div>
          </div>
        </div>
        <class-push :search="search" class="youlove">其他课程推荐</class-push>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { AnswerClient } from "@/api/task/answerClient";
import { getInfo } from "@/api/cookies";
import { getClass } from "@/api/home.js";
const answerClient = new AnswerClient();
import classPush from "@/components/Know/classPushPartner.vue";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();
import { getDomain } from "@/api/cookies";
export default {
  components: { classPush },
  data() {
    return {
      packId: "",
      detail: {}, // 试卷包详情
      list: [],
      userInfo: null,
      IsPurchase: false,
      classDetail: {},
      search: {
        kind: null,
      },
      Loading: false,
      copyright: {} /* { name: "同行转型", key: 1 },
    { name: "招商加盟", key: 2 },
    { name: "高校合伙人", key: 3 } */,
    };
  },
  created() {
    this.copyright = getDomain();
    this.packId = this.$route.query.packId
      ? String(this.$route.query.packId)
      : null;
    this.classId = this.$route.query.classId
      ? Number(this.$route.query.classId)
      : null;
    this.IsPurchase = eval(this.$route.query.IsPurchase);
    this.userInfo = getInfo();
    if (this.classId) {
      getClass(this.classId).then((res) => {
        this.classDetail = res.data.BX;
      });
    }
    this.getDetail();
  },
  methods: {
    // 获取试卷包列表
    getDetail() {
      answerClient
        .getPackageDetails(
          this.packId,
          this.userInfo ? this.userInfo.id : undefined,
          this.classId ? this.classId : undefined
        )
        .then((res) => {
          this.detail = res.data.paper;
          this.search.kind = res.data.paper.kindId;
          console.log(this.detail, "this.detail");
          this.detail.entrantsPrice =
            res.data?.product?.entrantsPrice ||
              res.data?.product?.entrantsPrice == 0
              ? res.data.product.entrantsPrice
              : res.data.paper.originalPrice;
          this.detail.SubjectNum = res.data.paper.paperNameList
            ? res.data.paper.paperNameList.length
            : 0;
          this.list = res.data.paper.paperNameList.map((item) => {
            this.$set(item, "loading", false);
            return item;
          });
          if (this.IsPurchase) {
            this.detail.status = 1;
          } else {
            this.IsPurchase = this.detail.status == 1;
          }
        });
      this.$forceUpdate();
    },

    /* 做题 */
    goLearn(item) {
      item.loading = true;
      answerClient.selectPaperSubjectList(item.id).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExamPartner(
            item.name,
            item.id,
            4,
            6,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.packId,
            undefined,
            this.classId ? this.classId : undefined
          );
        }
        item.loading = false;
      });
    },
    // 立即购买
    goOrder() {
      if (this.detail.status == 1) {
        return
      }
      if (this.userInfo) {
        if (this.classId) {
          orderClient
            .addOrders(
              this.classId,
              this.userInfo.id,
              this.classDetail.tiitle,
              1,
              this.classDetail.tiitle,
              this.classDetail.kind
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: "个人中心订单页",
                  params: { orderNumber: res.msg },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          orderClient
            .addOrders(
              this.detail.id,
              this.userInfo.id,
              this.detail.name,
              6,
              this.detail.name,
              this.detail.kindId
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: "个人中心订单页",
                  params: { orderNumber: res.msg },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
        }
      } else {
        Vue.prototype.goLoginView(true);
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.examPackPartner {
  width: 100%;

  .partenr_top {
    width: 100%;
    height: 251px;
    background: url("~@/assets/img/partner/paperbac.png") no-repeat;
    background-size: 100%;

    .top_center {
      width: 1200px;
      height: 140px;
      margin: 0 auto;

      .crumbs {
        padding-top: 19px;

        /deep/ .el-breadcrumb__item {
          .el-breadcrumb__inner,
          .el-breadcrumb__separator {
            color: #fff;
            font-size: 12px;
            margin: 0;
          }
        }
      }

      .name {
        font-size: 30px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        padding: 40px 0 16px;
        text-align: center;
      }

      .tip {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
      }
    }

    .top_tip {
      width: 1200px;
      // height: 120px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      margin: 43px auto 0;
      padding: 22px 40px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .tip_left {
        width: 624px;

        .tip_name {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666;
          line-height: 26px;

          .name {
            margin-right: 5px;
          }

          .tip {
            color: #999;
          }
        }

        .tip_tip {
          margin-top: 10px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;

          .one {
            color: #666;
            margin-right: 10px;
          }

          .three {
            margin: 0 10px;
          }
        }
      }

      .tip_right {
        display: flex;
        align-items: center;
        // margin-top: 12px;

        .right_price {
          font-size: 16px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          color: #f9431e;
          margin-right: 40px;

          .price {
            font-weight: 700;
            font-size: 24px;
          }
        }

        .right_btn {
          width: 172px;
          height: 56px;
          background: linear-gradient(88deg, #ff6d1e 0%, #fe420e 100%);
          border-radius: 28px 28px 28px 28px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }
  }

  .partenr_bottom {
    width: 1200px;
    padding-top: 55px;
    margin: 0 auto;

    .back {
      position: relative;
      width: 56px;
      height: 14px;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      margin: 20px 0px 24px;
      cursor: pointer;

      .line {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 4px;
        background: #1e82f5;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
      }
    }

    .list {
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      width: 852px;
      padding: 20px;
    }

    .item {
      position: relative;
      width: 812px;
      height: 70px;
      background: #f9f9f9;
      border-radius: 6px 6px 6px 6px;
      margin-bottom: 10px;
      opacity: 1;
      display: flex;
      align-items: center;
      padding: 0 20px;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 108px;
        height: 36px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 1px solid #b9bfc4;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #777a82;

        /deep/ .el-button {
          background: transparent;
        }
      }

      .btn:hover {
        border: 1px solid #2586f5;
        color: #2586f5;
        background-color: transparent;
      }

      .item-image {
        width: 33.3px;
        height: 36.5px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 12px;
      }

      .item-content {
        height: 37px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .item-title {
          height: 14px;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
          max-width: 650px;
        }

        .item-count {
          margin-top: 9px;
          height: 14px;
          line-height: 14px;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }

      .keep-learn {
        flex-shrink: 0;
      }
    }
  }
}

/deep/ .el-button {
  background: transparent !important;
}
</style>
